.app__footerOverlay {
  width: 100%;
  height: 100%;
  z-index: -1;
  background: #4a7f9c;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.app__footerOverlay-black {
  height: 25%;
  background: #4a7f9c;
}

.app__footerOverlay-img {
  height: 75%;
}
