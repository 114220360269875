.navbar {
  background-color: rgba(240, 230, 76, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  perspective: 1000px;
  transform-style: preserve-3d;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  transition: background-color 0.3s ease;
  padding: 0 1rem;
}

.navbar-scrolled {
  background-color: #4a7f9c;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 2rem;
}

.brand-name {
  font-weight: bold;
  text-decoration: none;
  color: #4a7f9c;
  font-size: 45px;
  letter-spacing: 0.2rem;
}

.brand-name-scrolled {
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 2px;
}

.brand-logo {
  width: 80px;
  height: 80px;
  margin-left: -30px;
}

.navbar-items {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.navbar-link {
  text-decoration: none;
  color: #4a7f9c;
  font-size: 1.6rem;
  font-weight: bold;
  transition: transform 0.3s, color 0.3s;
}

.navbar-item-scrolled {
  color: #ffffff;
}

.navbar-link:hover {
  transform: translateY(-30px);
  color: #f46020;
}
